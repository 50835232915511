import { useMemo } from 'react';
import { useIsLoggedIn } from 'bb/account';
import { useApi } from 'bb/api/browser/useApi';

export type ReturnType<T> = Set<T>;

export const useOwnedEditions = () => {
    const swrResponse = useApi('/api/my/editions/owned/all', {
        fixed: true,
        enabled: useIsLoggedIn()
    });

    const set = useMemo(
        () => new Set<string | null | undefined>(swrResponse.data),
        [swrResponse.data]
    );

    return useMemo(
        () =>
            Object.assign(swrResponse, {
                data: set
            }),
        [swrResponse, set]
    );
};
