import dynamic from 'next/dynamic';
import { withFeatureFlaggedComponent } from 'bb/page/withFeatureFlaggedComponent';

const OldComponent = dynamic(
    () => import('./BookCard').then((module) => module.BookCard),
    { ssr: true }
);

const NewComponent = dynamic(
    () =>
        import('../BookCardV2/BookCardLink').then(
            (module) => module.BookCardLink
        ),
    { ssr: true }
);

export const BookCard = withFeatureFlaggedComponent(
    OldComponent,
    NewComponent,
    'nextweb-book-card-refactor'
);
